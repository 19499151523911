import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Sidebar from '../../components/Sidebar';
import Topbar from '../../components/Topbar';
import Loading from '../../components/Loading';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { MDBDataTable } from 'mdbreact';
import { Select, Button, DatePicker, Row, Col } from 'antd';
import { CSVLink } from "react-csv";
import i18n from "i18next";
import dayjs from 'dayjs';
import { dateFormat, timeFormat } from '../../../../dynamicController';

const { RangePicker } = DatePicker;
const { Option } = Select;
const mySwal = withReactContent(Swal);

class DiscountCodeReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            data: [],
            loading: false,
            outletIds: [],
            startDate: "",
            endDate: ""
        };
    }
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            if (!this.props.admin.isAuthenticated || !this.props.admin.admin || !this.props.admin.admin.discount_code_report) {
                return this.props.history.push('/admin/dashboard');
            }
            this.handleFilter();
        }
    }

    componentWillUnmount() { this._isMounted = false; }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = outletIds => this.setState({ outletIds });

    handleDate = (dates, dateStrings) => {
        if (dates) {
            this.setState({
                startDate: dateStrings[0],
                endDate: dateStrings[1]
            });
        } else {
            this.setState({
                startDate: null,
                endDate: null
            });
        }
    };

    handleFilter = () => {
        const { outletIds, startDate, endDate } = this.state;
        const sendThis = {
            operatorId: this.props.admin.admin.operatorId,
            role: "operator",
            outletIds: outletIds.length > 0 ? outletIds : this.props.admin.admin.outlets.map(outlet => outlet.id)
        };
        if (startDate && endDate) {
            sendThis.startDate = startDate
            sendThis.endDate = endDate
        }

        this.setState({ loading: true });

        axios.post('/api/admin/report/discountCodeReport', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false
                });
                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
            });
    };

    handleReset = () => {
        this.setState({
            outletIds: [],
            startDate: "",
            endDate: "",
            data: []
        }, this.handleFilter);
    };

    render() {
        const { outletIds, data, loading, startDate, endDate } = this.state;
        const columns = [
            { label: i18n.t("Date"), field: "date", sort: "asc" },
            { label: i18n.t("Discount Code"), field: "code", sort: "asc" },
            { label: i18n.t("Discount Code Type"), field: "discount_type", sort: "asc" },
            { label: i18n.t("Discount Code Amount"), field: "discount_amount", sort: "asc" },
            { label: i18n.t("Outlet"), field: "outlet", sort: "asc" },
            { label: i18n.t("Number of Redemption"), field: "total_redeem", sort: "asc" },
            { label: i18n.t("Total Discount Amount"), field: "total_discount_amount", sort: "asc" },
        ];

        const csvData = {
            headers: [
                { label: "Date", key: "date" },
                { label: "Discount Code", key: "code" },
                { label: "Discount Code Type", key: "discount_type" },
                { label: "Discount Code Amount", key: "discount_amount" },
                { label: "Outlet", key: "outlet" },
                { label: "Number of Redemption", key: "total_redeem" },
                { label: "Total Discount Amount", key: "total_discount_amount" },
            ],
            data
        };

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{i18n.t("Discount Code Report")}</h5>
                            </div>

                            <div className="card-body">
                                <div className="mb-5">
                                    <h6>{i18n.t("Filter")}</h6>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <label htmlFor="outletId">{i18n.t("Select Outlet(s)")}: </label>
                                            <Select
                                                id="outletId"
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder={i18n.t("Select Outlet(s)")}
                                                value={outletIds}
                                                onChange={this.handleChange}
                                                showSearch
                                                optionFilterProp="label"
                                            >
                                                {this.props.admin?.admin?.outlets?.map(outlet => (
                                                    <Option
                                                        key={outlet.id}
                                                        value={outlet.id}
                                                        label={`${outlet.outlet_fullname} (${outlet.outlet_name})`}
                                                    >
                                                        {outlet.outlet_fullname} ({outlet.outlet_name})
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="mt-3">
                                        <Col span={24}>
                                            <label htmlFor="date">{i18n.t("Select Transaction Date")}: </label>
                                            <RangePicker
                                                onChange={this.handleDate}
                                                style={{ width: '100%' }}
                                                value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []}
                                                // ranges={{
                                                //     'Today': [dayjs(), dayjs()],
                                                //     'Yesterday': [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
                                                //     'Last 7 Days': [dayjs().subtract(6, 'day'), dayjs()],
                                                //     'Last 30 Days': [dayjs().subtract(29, 'day'), dayjs()],
                                                //     'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
                                                //     'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                                                // }}
                                                presets={[
                                                    { label: "Today", value: [dayjs(), dayjs()] },
                                                    { label: "Yesterday", value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')] },
                                                    { label: "Last 7 Days", value: [dayjs().subtract(6, 'day'), dayjs()] },
                                                    { label: "Last 30 Days", value: [dayjs().subtract(29, 'day'), dayjs()] },
                                                    { label: "This Month", value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                                                    { label: "Last Month", value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] }
                                                ]}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="mt-3">
                                        <Col span={12}>
                                            <Button type="primary" onClick={this.handleFilter} className="w-100">
                                                {i18n.t("Filter")}
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button onClick={this.handleReset} className="w-100">
                                                {i18n.t("Reset")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="text-center mt-5">
                                    {loading ? <Loading /> : (
                                        <div>
                                            <MDBDataTable data={{ columns, rows: data }} noBottomColumns striped hover responsive bordered />
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`discountcode_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                    {i18n.t("Download Report")}
                                                </CSVLink>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    admin: state.admin
});

export default connect(mapStateToProps, null)(DiscountCodeReport);
