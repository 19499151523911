import { useTranslation } from "react-i18next";

import { currency } from "../../../../../dynamicController";

const CleanproVending = ({ amount, setAmount, machine, minusAmount, addAmount, handleSubmit, user }) => {

    const { t } = useTranslation();

    return (
        <div>
            <div className="payment-section">
                <h6 style={{ margin: "20px 0 5px 0" }}>
                    <strong>{t("Select Payment Amount")}</strong>
                </h6>

                <div className="custom-duration mt-2">
                    <p id="cleanpro-minus-amount" onClick={(e) => minusAmount()}>-</p>
                    <div>
                        {
                            machine.type === "Billacceptor" ? (
                                <div className="d-flex">
                                    <h3 style={{ marginTop: "auto", marginBottom: "auto" }}>{currency(machine.country)}</h3>
                                    <input type="number" id="amount" name="amount" className="ms-2 form-control browser-default text-center" value={amount} onChange={e => setAmount(e.target.value)} required min="0" max="100" step={machine.step || 1} />
                                </div>
                            ) : (
                                <h3 style={{ marginTop: "auto", marginBottom: "auto" }}>
                                    {currency(machine.country)} {/\./g.test(amount.toString()) ? parseFloat(amount).toFixed(2) : amount}
                                </h3>
                            )
                        }
                    </div>
                    <p id="cleanpro-add-amount" onClick={(e) => addAmount()}>+</p>
                </div>
            </div>

            <div className="payment-section">
                <div className="d-flex justify-content-between py-2">
                    <h6>{t("Amount to Pay")}</h6>
                    <h6 className="font-weight-bold blue-t">
                        {user && user.tokenSetting ? "" : currency(machine.country)} {/\./g.test(amount.toString()) ? parseFloat(amount).toFixed(2) : amount} {user && user.tokenSetting ? t("credits") : ""}
                    </h6>
                </div>
            </div>

            <div className="mt-5 px-3">
                <button id="pay" onClick={e => handleSubmit(e)} className="btn form-control big-button">
                    {t("Pay now")}
                </button>
            </div>
        </div>
    )
}

export default CleanproVending;